<template>
  <v-dialog v-model="dialog" style="zIndex: 1000;" max-width="1500" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="white--text">Imprimir Rango de Fechas</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4" style="overflow: auto;">
        <table style="width: 100%;" v-if="ev.length > 0">
          <thead>
            <tr>
              <th style="white-space: nowrap;">DÍA</th>
              <th style="white-space: nowrap;">FECHA</th>
              <th style="white-space: nowrap;">EVENTO</th>
              <th style="white-space: nowrap;">AD</th>
              <th style="white-space: nowrap;">N</th>
              <th style="white-space: nowrap;">TOT</th>
              <th style="white-space: nowrap;">ESPACIO</th>
              <th style="white-space: nowrap;">SALÓN</th>
              <th style="white-space: nowrap;">HORARIO</th>
              <th style="white-space: nowrap;">BREVE DATO</th>
              <th style="white-space: nowrap;">A TENER EN CUENTA</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(e, i) in ev">
              <tr v-if="(i > 0 && ev[i-1].date != e.date)">
                <td colspan="11" style="border-top: 4px solid var(--v-secondary-base);border-bottom: 4px solid var(--v-secondary-base);"></td>
              </tr>
              <tr>
                <td class="py-1 px-1 font-weight-bold" style="white-space: nowrap;text-align: center;">{{ new Date(e.date).toLocaleDateString('esp', { weekday : 'short' }).toUpperCase() }}</td>
                <td class="py-1 px-1 font-weight-bold" style="white-space: nowrap;text-align: center;">{{ ('0' + new Date(e.date).getDate()).slice(-2) }}-{{ new Date(e.date).toLocaleDateString('esp', { month : 'short'}).toUpperCase() }}</td>
                <td class="py-1 px-1 font-weight-bold" :style="'white-space: nowrap;color: ' + e.color">{{ e.datos.novios || e.datos.nino || e.datos.tipoCelebracion || e.cliente.nombreCompleto.split(" ")[0] || "" }}</td>
                <td class="py-1 px-1 font-weight-bold" style="white-space: nowrap;text-align: center;">{{ (+e.datos.nAdultos || 0) }}</td>
                <td class="py-1 px-1 font-weight-bold" style="white-space: nowrap;text-align: center;">{{ (+e.datos.nNinos || 0) }}</td>
                <td class="py-1 px-1 font-weight-bold" style="white-space: nowrap;text-align: center;">{{ (+e.datos.nAdultos || 0) + (+e.datos.nNinos || 0) }}</td>
                <td class="py-1 px-1 font-weight-bold" style="white-space: nowrap;text-align: center;">{{ e.siglasEspacio }}</td>
                <td class="py-1 px-1 font-weight-bold" style="white-space: nowrap;text-align: center;">{{ e.datos.idEspacio == 10 ? e.datos.ciudadCat : ((e.siglasSalon != 'null' && e.siglasSalon) ? e.siglasSalon : '') }}</td>
                <td class="py-1 px-1 font-weight-bold" style="white-space: nowrap;text-align: center;">{{ e.siglasHorario }}</td>
                <td class="py-1 px-1 font-weight-bold" style="white-space: nowrap;">{{ e.datos.breveDato }}</td>
                <td class="py-1 px-1 font-weight-bold" style="white-space: nowrap;"><v-text-field v-model="notas[i]" filled dense hide-details="auto"></v-text-field></td>
              </tr>
            </template>
            <tr>
              <td colspan="11">
                <v-textarea filled dense v-model="notasgnl" placeholder="A tener en cuenta..." style="width: 100%"></v-textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="imprimir">Imprimir</v-btn>
        <v-btn color="grey" text @click.native="cancel">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
	props: ["eventos", "rango"],
  data: () => ({
    ev: [],
    dialog: false,
    notas: [],
    notasgnl: '',
  }),
  methods: {
    open(rango){
      rango = rango.sort()
      this.ev = this.eventos.filter(e => e.date <= rango[1] && e.date >= rango[0]).sort(function (a, b) { if (a.datos.fechaInicioEvento < b.datos.fechaInicioEvento) return -1; if (a.datos.fechaInicioEvento > b.datos.fechaInicioEvento) return 1; return 0 })
      this.ev.forEach((e,i) => {
        if (e.idTipoEvento == 5) this.notas[i] = e.datos.ubBodaReal
      })
      this.dialog = true
    },
    cancel(){
      this.dialog = false
      this.ev = []
      this.notas = []
      this.notasgnl = ''
    },
    imprimir(){
      var v = window.open('', '_blank','')
      v.document.write('<head><title>' + this.rango[0] + ' - ' + this.rango[1] +'</title><style>ul {font-size: 14px !important};</style></head>');
      var l = v.document.createElement('link');
      l.rel = 'stylesheet';
      l.href = 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900';
      //titulo
      var wrapper = v.document.createElement('div')
      wrapper.style = "display:block;-webkit-print-color-adjust:exact !important;print-color-adjust:exact !important;"
      wrapper.innerHTML = '<div style="display:flex;justify-content: space-between"><div style="font-family: \'Roboto\', sans-serif;display:flex; align-items:center"><h3>RANGO DE FECHAS</h3></div></div><hr/>'

      var tabla = v.document.createElement('table')
      tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif"
      var thead = v.document.createElement('thead')
      thead.innerHTML = '<tr><th style="padding: 3px; border:2px solid gray">DÍA</th><th style="padding: 3px; border:2px solid gray">FECHA</th><th style="padding: 3px; border:2px solid gray">EVENTO</th><th style="padding: 3px; border:2px solid gray">AD</th><th style="padding: 3px; border:2px solid gray">N</th><th style="padding: 3px; border:2px solid gray">TOT</th><th style="padding: 3px; border:2px solid gray">ESP</th><th style="padding: 3px; border:2px solid gray">SAL</th><th style="padding: 3px; border:2px solid gray">Hº</th><th style="padding: 3px; border:2px solid gray">DATO</th><th style="padding: 3px; border:2px solid gray">NOTA</th></tr>'
      tabla.appendChild(thead)
      var tbody = v.document.createElement('tbody')

      this.ev.forEach((e, i) => {
        if (i > 0 && this.ev[i-1].date != e.date){
          var trAux = v.document.createElement('tr')
          trAux.innerHTML += '<td colspan="11" style="border-top: 4px solid #424242;border-bottom: 4px solid #424242;"></td>';
          tbody.appendChild(trAux)
        }
        var tr = v.document.createElement('tr')
        tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;text-align:center'>" + (new Date(e.date).toLocaleDateString('esp', { weekday : 'short' }).toUpperCase()) + "</td>";
        tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;text-align:center'>" + (('0' + new Date(e.date).getDate()).slice(-2) + "-" + new Date(e.date).toLocaleDateString('esp', { month : 'short'})) + "</td>";
        tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;font-size:xx-small;font-weight: bold; color: " + e.color + "'>" + (e.datos.novios || e.datos.nino || e.datos.tipoCelebracion || e.cliente.nombreCompleto.split(" ")[0] || "") + "</td>";
        tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;text-align:center'>" + (+e.datos.nAdultos || 0) + "</td>";
        tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;text-align:center'>" + (+e.datos.nNinos || 0) + "</td>";
        tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;text-align:center'>" + ((+e.datos.nAdultos || 0) + (+e.datos.nNinos || 0)) + "</td>";
        tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;text-align:center'>" + (e.siglasEspacio || '') + "</td>";
        tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;text-align:center;" + (e.datos.idEspacio == 10 ? "font-size:xx-small;font-weight:bold;" : "") +"'>" + (e.datos.idEspacio == 10 ? e.datos.ciudadCat : ((e.siglasSalon != 'null' && e.siglasSalon) ? e.siglasSalon : '')) + "</td>";
        tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;text-align:center'>" + (e.siglasHorario || '') + "</td>";
        tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;font-size:xx-small;'>" + (e.datos.breveDato || '') + "</td>";
        tr.innerHTML += "<td style='padding: 3px; border:2px solid gray;font-size:xx-small;color: #0086b3'>" + (this.notas[i] ? this.notas[i].replace(/\n/gi,'<br>') : '') + "</td>";
        tbody.appendChild(tr)
      });
      if(this.notasgnl.length > 0) tbody.innerHTML += "<td style='padding: 3px; border:2px solid gray;color: #0086b3' colspan='11'>" + this.notasgnl.replace(/\n/gi,'<br>') + "</td>";
      tabla.appendChild(tbody)
      wrapper.appendChild(tabla)
      v.document.write(wrapper.outerHTML)
      v.print()
      // v.close()
    }
  },
}
</script>